<script>
import GlobalVue from '@root/helper/Global.vue';
import Gen from '@helper/Gen';
import BOGen from '@helper/BOGen';
export default {
    name: "NavBar",
    extends: GlobalVue,
    data(){
        return{
            notif:{}
        }
    },
    mounted(){
        window.nav = this
    },
    methods:{}
};
</script>
<template>
<header class="topbar">
    <nav class="navbar top-navbar navbar-expand-md navbar-dark">
        <div class="navbar-header  text-center">
            <a class="navbar-brand" >
                <img :src="assets('fo_images','vvl_logo.png')" width="60px;" alt="Logo" class="dark-logo" />
            </a>
        </div>
        <div class="navbar-collapse">
            <ul class="navbar-nav mr-auto">
                <li class="nav-item"> <a class="nav-link nav-toggler d-block d-md-none waves-effect waves-dark" href="javascript:void(0)"><i class="ti-menu"></i></a> </li>
                <li class="nav-item"> <a class="nav-link sidebartoggler d-none d-lg-block d-md-block waves-effect waves-dark" href="javascript:void(0)"><i class="icon-menu"></i></a> </li>
            </ul>

            <ul class="navbar-nav my-lg-0">
                <li class="nav-item right-side-toggle"> <a class="nav-link  waves-effect waves-light" href="javascript:void(0)">Backoffice Ver 6.0</a></li>
            </ul>
        </div>
    </nav>
</header>
</template>