<script>
import GlobalVue from '@root/helper/Global.vue';
export default {
    extends: GlobalVue,
    name: "RightSideBar",
};
</script>
<template>
<div class="right-sidebar">
    <div class="slimscrollright">
        <div class="rpanel-title"> Service Panel <span><i class="ti-close right-side-toggle"></i></span> </div>
        <div class="r-panel-body">
            <ul class="m-t-20 chatonline">
            </ul>
        </div>
    </div>
</div>
</template>